import React from "react"
import Layout from "../components/layout"
import ourTeamStyles from "../styles/our_team_styles.module.scss"
import { Row, Col, Divider } from "antd"

const OurTeam = () => {
  return (
    <Layout>
      <Row align="middle" justify="center" gutter={[0, 48]}>
        <Col span={24}>
          <div className={ourTeamStyles.heroImage}>
            <div className={ourTeamStyles.heroText}>
              <h2>Our Team</h2>
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={[0, 48]} className={ourTeamStyles.even}>
        <Col
          span={4}
          offset={4}
          xs={{ span: 20, offset: 2 }}
          md={{ span: 8, offset: 2 }}
          lg={{ span: 4, offset: 4 }}
        >
          <div
            id={ourTeamStyles.rajendraAnand}
            className={ourTeamStyles.image}
          />
        </Col>
        <Col
          span={12}
          offset={1}
          xs={{ span: 20, offset: 2 }}
          md={{ span: 10, offset: 2 }}
          lg={{ span: 12, offset: 1 }}
        >
          <h3>
            Dr. Rajendra Anand
            <span>
              <h4>M.D. (Psychiatry)</h4>
            </span>
          </h3>
          <p>
            Dr. Rajendra Anand is a De-Addiction and Mental Health Expert. He
            has completed MBBS in 1986 from Smt. NHL Medical College and MD
            (Psychiatry) in 1990 from Sheth K.M. School of Post Graduate, VS
            Hospital. He has been practicing for last 30 years as a Consulting
            Psychiatrist. He is also working as a Chief Executive Officer at
            Kanoria Hospital and Research Centre since 1995. <br />
            <br />
            His primary areas of interest lies in De-addiction, Child
            Psychiatry, and Social Psychiatry. He has also been a Principal
            Investigator in conducting over 100 clinical trials over the course
            of 10 years. He organized over 200 de-addiction and free awareness
            camps, conducted more than 300 lectures and seminars on Psychiatry,
            reached and treated over 1 lakh patients in the course of 30 years.
            He also conduct lectures in various nursing colleges.{" "}
          </p>
        </Col>
      </Row>
      <Divider />
      <Row gutter={[0, 48]} className={ourTeamStyles.odd}>
        <Col
          span={4}
          offset={4}
          xs={{ span: 20, offset: 2 }}
          md={{ span: 8, offset: 2 }}
          lg={{ span: 4, offset: 4 }}
        >
          <div
            id={ourTeamStyles.jitendraAnand}
            className={ourTeamStyles.image}
          />
        </Col>
        <Col
          span={12}
          offset={1}
          xs={{ span: 20, offset: 2 }}
          md={{ span: 10, offset: 2 }}
          lg={{ span: 12, offset: 1 }}
        >
          <h3>
            Dr. Jitendra Anand
            <span>
              <h4>M.D. (Medicine)</h4>
            </span>
          </h3>
          <p>
            Dr. Jitendra Anand is Consulting Physician. He has completed MBBS
            from Smt. NHL Medical College. Completed MD (General Medicine) from
            Sheth K. M. School of Post Graduate Medicine and Research attached
            to Sheth V.S. Hospital. He also has been awarded with PhD in Medical
            Biochemistry in 2010 from Smt. NHL Medical College. <br /><br />
            He was a Lecturer in B.J. Medical College and Civil Hospital,
            Ahmedabad, Gujarat from 1992 to 2018. At present he is working as a
            Consulting Physician at Lokhandwala General Hospital, Ahmedabad and
            Anand Multispeciality Hospital, Gandhinagar. He is one of the office
            bearers of Association of Physicians of Gujarat, Association of
            Physicians of Ahmedabad and Cardiology Society of India (Ahmedabad
            chapter).
          </p>
        </Col>
      </Row>
      <Divider />
      <Row gutter={[0, 48]} className={ourTeamStyles.even}>
        <Col
          span={4}
          offset={4}
          xs={{ span: 20, offset: 2 }}
          md={{ span: 4, offset: 2 }}
          lg={{ span: 4, offset: 4 }}
        >
          <div
            className={`${ourTeamStyles.unnamedFemale} ${ourTeamStyles.image}`}
          />
          <br />
          <h4>
            Dr. Jignasha Bhabhor
            <span>
              <h5>M.S. (Opthalmology)</h5>
            </span>
          </h4>
        </Col>
        <Col
          span={4}
          offset={1}
          xs={{ span: 20, offset: 2 }}
          md={{ span: 4, offset: 1 }}
          lg={{ span: 4, offset: 1 }}
        >
          <div
            className={`${ourTeamStyles.unnamedMale} ${ourTeamStyles.image}`}
          />
          <br />
          <h4>
            Dr. Perlin Mamavadariya
            <span>
              <h5>M.D. (Psychiatry)</h5>
            </span>
          </h4>
        </Col>
        <Col
          span={4}
          offset={1}
          xs={{ span: 20, offset: 2 }}
          md={{ span: 4, offset: 1 }}
          lg={{ span: 4, offset: 1 }}
        >
          <div
            className={`${ourTeamStyles.unnamedMale} ${ourTeamStyles.image}`}
          />
          <br />
          <h4>
            Dr. Amit Babel
            <span>
              <h5>M.S. (ENT)</h5>
            </span>
          </h4>
        </Col>
        <Col
          span={4}
          offset={1}
          xs={{ span: 20, offset: 2 }}
          md={{ span: 4, offset: 1 }}
          lg={{ span: 4, offset: 1 }}
        >
          <div
            className={`${ourTeamStyles.unnamedFemale} ${ourTeamStyles.image}`}
          />
          <br />
          <h4>
            Dr. Mittal Mehta
            <span>
              <h5>M.S. (ENT)</h5>
            </span>
          </h4>
        </Col>
      </Row>
    </Layout>
  )
}

export default OurTeam
